import React, { Dispatch, SetStateAction, useMemo } from 'react';

import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';

interface Props {
  selectedPersonId: string | null;
  setSelectedPersonId: Dispatch<SetStateAction<string | null>>;
  allAkoodaEmployees: { _id: string; name: string; id: string }[];
}

export default function AkoodaEmployeeSelection({
  selectedPersonId, setSelectedPersonId, allAkoodaEmployees,
}: Props) {
  const options = useMemo(() => [
    { value: null, label: 'All Akooda Employees' },
    ...allAkoodaEmployees.map(({ _id, name, id }) => ({ value: _id, label: `${name} - ${id}` })),
  ], [allAkoodaEmployees]);

  return (
    <SearchableSelectionDropdown<string | null>
      options={options}
      selectedValue={selectedPersonId}
      selectValue={setSelectedPersonId}
    />
  );
}
